.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.aa-Label {
  margin-bottom: 0;
}

:root {
  --aa-InputWrapperPrefix: 0;
}

.aa-Panel {
  width: max-content !important;
  z-index: 15999;
}

@media (max-width: 1302px) {
  .aa-Panel {
    left: 0 !important;
    margin-inline: auto;
    right: 0 !important;
  }
}

/*CX Algolia facets class*/

.ais-SearchBox-form{
  position: relative;
}
.ais-SearchBox-submit
 {
  display: none;
}
.ais-SearchBox-reset{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-4px,50%);
    border-radius: 50rem;
    border: none;
    background: none;
}
.ais-SearchBox-input {
  width: 95%;
  margin: 5px 2px;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid #f4e5d9;
}
.ais-RefinementList-item {
  margin: 10px 0;
}
.ais-RefinementList-label {
  display: grid;
  grid-template-columns:30px 2fr 25%;
  gap: 10px;
  align-items: center;
  font-size: 16px;
}
.ais-RefinementList-checkbox {
  color: black;
  box-shadow: none;
  outline: 0;
  height: 20px;
  accent-color: #f4e5d9;
}
.ais-RefinementList-labelText{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ais-RefinementList-count{
  justify-self: end;
}
.ais-RefinementList-count::before{
 content: "(";
 clear: both;
}
.ais-RefinementList-count::after{
 content: ")";
 clear: both;
}
.ais-Highlight-highlighted{
  font-weight: 700;
  background-color: transparent;
  color: inherit;
}
.ais-ClearRefinements{
  margin : 10px 2px
}
.ais-ClearRefinements-button{
  background-color: #f4e5d9;
  border: 2px solid #9c1452;
  font-size: 1.6rem;
}
.cx-ais-ClearRefinements{
  margin : 10px 2px
}
.cx-ais-ClearRefinements-button{
  background-color: #f4e5d9;
  border: 2px solid #9c1452;
  font-size: 1.6rem;
  display: inline;
  padding:2px 6px;
  cursor: pointer ;
}
.cx-ais-ClearRefinements-button>a{
text-decoration: none;
}
.ais-CurrentRefinements-list{
  list-style: none;
  padding :  0px ;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.ais-CurrentRefinements-item{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 0px;
  margin: 5px 0px; 
  padding:5px 10px;
  width:100%;
  /* border:2px solid #f4e5d9;
  border-radius:2rem */
}
.ais-CurrentRefinements-label{
  max-width: 80%;
}
.ais-CurrentRefinements-category{
  display:flex;
  align-items: center;
  gap:5px;
}
.ais-CurrentRefinements-delete{
  border-radius:50%;
  color:white;
  background-color:#f4e5d9;
  margin: 0px 6px;
  border:none
}
.ais-RefinementList-showMore--disabled{
  display:none
}
.cx-CustomCurrentRefinementList-wrapper{
  display: flex;
  flex-wrap: wrap;
}

.pr-group{
  display: flex;
}
.pr-input{
  width:12rem;
  border: solid 1px #adadad;
  padding: 5px 10px;
}
.pr-input:focus{
  border: solid 1px #adadad;
}
.pr-span{
  padding: 5px 10px;
}
.pr-btn{
  padding: 5px 10px;
  margin-left: 10px;
  background-color:#d4865e;
  border: none;
  border-radius: 5px; 
  color: white;
}
.aa-SourceHeaderTitle{
  margin-left: 10px;
}
.aa-ItemLink{
  margin-left: 10px;
}
.aa-BreadCrumbLink{
  font-size: 16px;
  color:"#1e1e1f";
  text-decoration: none;
}
.aa-BreadCrumbLink:hover{
  text-decoration: underline;
}
.aa-SourceHeaderLine{
  margin-left: 10px;
}

.dynamic__title {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  color: #144D78;
  margin: 0 0 1em;
}

.boutique-carousel__container .boutique-carousel__wrapper {
  margin: 15px 0px;
}

.boutique-carousel__item .quickview-hovereffect .quickview-overlay {
  max-height: 2.65em;
  top: 56.66%;
}

.boutique-carousel__item-image {
  flex: 0 0 100%;
}

.boutique-carousel__item--skeleton::before {
  padding-bottom: 249px
}

.boutique-carousel__item .boutique-carousel__item-image a {
  text-decoration: none;
}

.boutique-carousel__item .boutique-carousel__item-image a p {
  margin: 6px 0px;
}

.boutique-carousel__item .boutique-carousel__item-image img {
  width: 100%;
  max-width: 70%;
  margin: 0px auto;
}
#banner-v7hkeulNz8FvXc1HJ1um .has-border .c-banner__category-image,
#banner-v7hkeulNz8FvXc1HJ1um .has-border .c-banner__category-custom-image {
   background-color : #020202;
}
.rheostat{
  margin-right: 10px;
  margin-left:15px;
  margin-bottom: 20px;
}
